@import '../../assets/style/utils.scss';

.contact {
    .blackbox {
        transition: ease transform 0.2s;
        margin-right: 10px;
        border-radius: 6px;
        padding: 10px;

        a {
            color: #ffffff;
            text-decoration: none;
            display: flex;
            img {
                margin-right: 5px;
                width: 1.3em;
                height: 1.3em;
            }
        }

        &:hover {
            transform: translateY(-3px);
        }
    }
}