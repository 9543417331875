/*
Fonts

https://fonts.google.com/specimen/Playfair+Display weight: Regular 400, Bold 600
https://fonts.google.com/specimen/Montserrat weight: Regular 400, Bold 600
https://fonts.google.com/specimen/Overpass+Mono  weight: Semi-Bold 600
*/


// Font Imports
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz@8..144&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overpass+Mono:wght@600&display=swap');

// Font Variables
// $font-display: 'Oswald', sans-serif;
$font-display: 'Poppins', sans-serif;
$font-copy: 'Poppins', sans-serif;
$font-mono: 'Overpass Mono', monospace;