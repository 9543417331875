/*
Colors
*/

$black: #000000;
$white: #ffffff;

$navy-blue: #000080;
$vegas-gold: #c8ad55;
$international-orange-engineering: #b80c09;
$seal-brown: #6b2b06;
$black-chocolate: #141301;

$color-one: $navy-blue;
$color-two: $vegas-gold;
$color-three: $international-orange-engineering;
$color-four: $seal-brown;
$color-five: $black-chocolate;
