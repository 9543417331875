@import './assets/style/utils.scss';

// base font size
html {
  font-size: 16px;
}


nav {
  ul {
    li {
      font-size: 20px;
      font-family: $font-copy;
      font-family: $font-display;
    }
  }
}


.border-container {

  --border-color: #000000;
  --border-width: 12.5px;

  @media (min-width:961px) {
    --border-width: 20px;

  }



  width: 100vw;
  height: 100vh;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--border-width);
  box-sizing: border-box;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &-border {

      &-top,
      &-bottom {
        height: var(--border-width);
        width: 100%;
        background-color: var(--border-color);
      }
    }

    &-center {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;

      &-middle {
        width: 100%;
        height: calc(100vh - var(--border-width) * 4);
        padding: calc(var(--border-width) * 2);
        box-sizing: border-box;

        &-content {
          height: calc(100vh - var(--border-width) * 8);
          overflow: scroll;
          box-sizing: border-box;
        
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
  
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      .content-border {

        &-left {
          width: var(--border-width);
          height: 100%;
          background-color: var(--border-color);
        }
        &-right {
          width: var(--border-width);
          height: 100%;
          display: flex;
          flex-direction: column;

          &-upper {
            width: var(--border-width);
            flex: 1;
            background-color: var(--border-color);
          }

          &-lower {
            width: var(--border-width);
            height: calc(var(--border-width) * 1.25);
            background-color: var(--border-color);
          }
        }
      }
    }
  }
}