/*

Typography Rules
3 Categories: Display (headlines), Copytext (p, span etc), Mono (quotes, mono)

*/

html, body {
    font-family: $font-copy;
}


//// General Rules
h1, h2, h3, h4, h5, h6 {
    text-align: left;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
}

ul, ol, li,
p, span,
q, blockquote, cite, code, mark, tt {
    text-align: left;
    font-style: normal;
    font-weight: 400;
}



//// Mobile Styles

// Display

.font-display, h1, h2, h3, h4, h5, h6 {
    font-family: $font-display;

    &.bold {
        font-weight: 600;
    }
}

h1 {
    font-size: 3.5rem;
    line-height: 3.5rem;
}

h2 {
    font-size: 2.75rem;
    line-height: 3.5rem;
}

h3 {
    font-size: 2.25rem;
    line-height: 3rem;
}

h4 {
    font-size: 2rem;
    line-height: 2.75rem;
}

h5 {
    font-size: 1.5rem;
    line-height: 2rem;
}

h6 {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

// Copytext
.font-copy, p, span {
    font-family: $font-copy;

    &.bold {
        font-weight: 600;
    }
}

p {
    margin-bottom: 20px;
    line-height: 1.3em;
}
// quotes

q {
    font-family: $font-copy;
    font-weight: 400;

    &.bold {
        font-weight: 600;
    }
}

blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";

    font-family: $font-display;
    font-weight: 400;
    font-style: italic;

    &.bold {
        font-weight: 600;
    }
}

blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

blockquote p {
    display: inline;
}

// Mono
.font-mono, cite, code, mark, tt {
    font-family: $font-mono;
}

code {
    color: $white;
    background-color: $color-five;
    padding: 2px 5px;
}


//// Desktop Styles

@media only screen and (min-width: 961px) {
    h1 {
        font-size: 5.25rem;
        line-height: 6rem;
    }

    h2 {
        font-size: 4.5rem;
        line-height: 5.25rem;
    }

    h3 {
        font-size: 3.5rem;
        line-height: 4.5rem;
    }

    h4 {
        font-size: 2.75rem;
        line-height: 3.5rem;
    }

    h5 {
        font-size: 2rem;
        line-height: 2.75rem;
    }

    h6 {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}




.blackbox {
    display: inline-block;
    background-color: black;
    color: white;
    padding: 5px;
    font-weight: 600;
}